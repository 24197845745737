/**
 * Internal Dependencies
 */
import display from './display';

const rendererSettings = {
	display,
};

export default rendererSettings;
