'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.iterator = exports.array = exports.object = exports.error = exports.any = undefined;

var _is = require('../utils/is');

var _is2 = _interopRequireDefault(_is);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var any = exports.any = function any(value, next, rungen, yieldNext) {
  yieldNext(value);
  return true;
};

var error = exports.error = function error(value, next, rungen, yieldNext, raiseNext) {
  if (!_is2.default.error(value)) return false;
  raiseNext(value.error);
  return true;
};

var object = exports.object = function object(value, next, rungen, yieldNext, raiseNext) {
  if (!_is2.default.all(value) || !_is2.default.obj(value.value)) return false;
  var result = {};
  var keys = Object.keys(value.value);
  var count = 0;
  var hasError = false;
  var gotResultSuccess = function gotResultSuccess(key, ret) {
    if (hasError) return;
    result[key] = ret;
    count++;
    if (count === keys.length) {
      yieldNext(result);
    }
  };

  var gotResultError = function gotResultError(key, error) {
    if (hasError) return;
    hasError = true;
    raiseNext(error);
  };

  keys.map(function (key) {
    rungen(value.value[key], function (ret) {
      return gotResultSuccess(key, ret);
    }, function (err) {
      return gotResultError(key, err);
    });
  });

  return true;
};

var array = exports.array = function array(value, next, rungen, yieldNext, raiseNext) {
  if (!_is2.default.all(value) || !_is2.default.array(value.value)) return false;
  var result = [];
  var count = 0;
  var hasError = false;
  var gotResultSuccess = function gotResultSuccess(key, ret) {
    if (hasError) return;
    result[key] = ret;
    count++;
    if (count === value.value.length) {
      yieldNext(result);
    }
  };

  var gotResultError = function gotResultError(key, error) {
    if (hasError) return;
    hasError = true;
    raiseNext(error);
  };

  value.value.map(function (v, key) {
    rungen(v, function (ret) {
      return gotResultSuccess(key, ret);
    }, function (err) {
      return gotResultError(key, err);
    });
  });

  return true;
};

var iterator = exports.iterator = function iterator(value, next, rungen, yieldNext, raiseNext) {
  if (!_is2.default.iterator(value)) return false;
  rungen(value, next, raiseNext);
  return true;
};

exports.default = [error, iterator, array, object, any];