'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createChannel = exports.subscribe = exports.cps = exports.apply = exports.call = exports.invoke = exports.delay = exports.race = exports.join = exports.fork = exports.error = exports.all = undefined;

var _keys = require('./keys');

var _keys2 = _interopRequireDefault(_keys);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var all = exports.all = function all(value) {
  return {
    type: _keys2.default.all,
    value: value
  };
};

var error = exports.error = function error(err) {
  return {
    type: _keys2.default.error,
    error: err
  };
};

var fork = exports.fork = function fork(iterator) {
  for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }

  return {
    type: _keys2.default.fork,
    iterator: iterator,
    args: args
  };
};

var join = exports.join = function join(task) {
  return {
    type: _keys2.default.join,
    task: task
  };
};

var race = exports.race = function race(competitors) {
  return {
    type: _keys2.default.race,
    competitors: competitors
  };
};

var delay = exports.delay = function delay(timeout) {
  return new Promise(function (resolve) {
    setTimeout(function () {
      return resolve(true);
    }, timeout);
  });
};

var invoke = exports.invoke = function invoke(func) {
  for (var _len2 = arguments.length, args = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    args[_key2 - 1] = arguments[_key2];
  }

  return {
    type: _keys2.default.call,
    func: func,
    context: null,
    args: args
  };
};

var call = exports.call = function call(func, context) {
  for (var _len3 = arguments.length, args = Array(_len3 > 2 ? _len3 - 2 : 0), _key3 = 2; _key3 < _len3; _key3++) {
    args[_key3 - 2] = arguments[_key3];
  }

  return {
    type: _keys2.default.call,
    func: func,
    context: context,
    args: args
  };
};

var apply = exports.apply = function apply(func, context, args) {
  return {
    type: _keys2.default.call,
    func: func,
    context: context,
    args: args
  };
};

var cps = exports.cps = function cps(func) {
  for (var _len4 = arguments.length, args = Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
    args[_key4 - 1] = arguments[_key4];
  }

  return {
    type: _keys2.default.cps,
    func: func,
    args: args
  };
};

var subscribe = exports.subscribe = function subscribe(channel) {
  return {
    type: _keys2.default.subscribe,
    channel: channel
  };
};

var createChannel = exports.createChannel = function createChannel(callback) {
  var listeners = [];
  var subscribe = function subscribe(l) {
    listeners.push(l);
    return function () {
      return listeners.splice(listeners.indexOf(l), 1);
    };
  };
  var next = function next(val) {
    return listeners.forEach(function (l) {
      return l(val);
    });
  };
  callback(next);

  return {
    subscribe: subscribe
  };
};