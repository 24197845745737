/**
 * Internal Dependencies
 */
import display from './display';
import mergeTag from './merge-tag';

const rendererSettings = {
	display,
	mergeTag,
};
export default rendererSettings;
