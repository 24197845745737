'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.race = exports.join = exports.fork = exports.promise = undefined;

var _is = require('../utils/is');

var _is2 = _interopRequireDefault(_is);

var _helpers = require('../utils/helpers');

var _dispatcher = require('../utils/dispatcher');

var _dispatcher2 = _interopRequireDefault(_dispatcher);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var promise = exports.promise = function promise(value, next, rungen, yieldNext, raiseNext) {
  if (!_is2.default.promise(value)) return false;
  value.then(next, raiseNext);
  return true;
};

var forkedTasks = new Map();
var fork = exports.fork = function fork(value, next, rungen) {
  if (!_is2.default.fork(value)) return false;
  var task = Symbol('fork');
  var dispatcher = (0, _dispatcher2.default)();
  forkedTasks.set(task, dispatcher);
  rungen(value.iterator.apply(null, value.args), function (result) {
    return dispatcher.dispatch(result);
  }, function (err) {
    return dispatcher.dispatch((0, _helpers.error)(err));
  });
  var unsubscribe = dispatcher.subscribe(function () {
    unsubscribe();
    forkedTasks.delete(task);
  });
  next(task);
  return true;
};

var join = exports.join = function join(value, next, rungen, yieldNext, raiseNext) {
  if (!_is2.default.join(value)) return false;
  var dispatcher = forkedTasks.get(value.task);
  if (!dispatcher) {
    raiseNext('join error : task not found');
  } else {
    (function () {
      var unsubscribe = dispatcher.subscribe(function (result) {
        unsubscribe();
        next(result);
      });
    })();
  }
  return true;
};

var race = exports.race = function race(value, next, rungen, yieldNext, raiseNext) {
  if (!_is2.default.race(value)) return false;
  var finished = false;
  var success = function success(result, k, v) {
    if (finished) return;
    finished = true;
    result[k] = v;
    next(result);
  };

  var fail = function fail(err) {
    if (finished) return;
    raiseNext(err);
  };
  if (_is2.default.array(value.competitors)) {
    (function () {
      var result = value.competitors.map(function () {
        return false;
      });
      value.competitors.forEach(function (competitor, index) {
        rungen(competitor, function (output) {
          return success(result, index, output);
        }, fail);
      });
    })();
  } else {
    (function () {
      var result = Object.keys(value.competitors).reduce(function (p, c) {
        p[c] = false;
        return p;
      }, {});
      Object.keys(value.competitors).forEach(function (index) {
        rungen(value.competitors[index], function (output) {
          return success(result, index, output);
        }, fail);
      });
    })();
  }
  return true;
};

var subscribe = function subscribe(value, next) {
  if (!_is2.default.subscribe(value)) return false;
  if (!_is2.default.channel(value.channel)) {
    throw new Error('the first argument of "subscribe" must be a valid channel');
  }
  var unsubscribe = value.channel.subscribe(function (ret) {
    unsubscribe && unsubscribe();
    next(ret);
  });

  return true;
};

exports.default = [promise, fork, join, race, subscribe];