"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var createDispatcher = function createDispatcher() {
  var listeners = [];

  return {
    subscribe: function subscribe(listener) {
      listeners.push(listener);
      return function () {
        listeners = listeners.filter(function (l) {
          return l !== listener;
        });
      };
    },
    dispatch: function dispatch(action) {
      listeners.slice().forEach(function (listener) {
        return listener(action);
      });
    }
  };
};

exports.default = createDispatcher;