'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _builtin = require('./controls/builtin');

var _builtin2 = _interopRequireDefault(_builtin);

var _is = require('./utils/is');

var _is2 = _interopRequireDefault(_is);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var create = function create() {
  var userControls = arguments.length <= 0 || arguments[0] === undefined ? [] : arguments[0];

  var controls = [].concat(_toConsumableArray(userControls), _toConsumableArray(_builtin2.default));

  var runtime = function runtime(input) {
    var success = arguments.length <= 1 || arguments[1] === undefined ? function () {} : arguments[1];
    var error = arguments.length <= 2 || arguments[2] === undefined ? function () {} : arguments[2];

    var iterate = function iterate(gen) {
      var yieldValue = function yieldValue(isError) {
        return function (ret) {
          try {
            var _ref = isError ? gen.throw(ret) : gen.next(ret);

            var value = _ref.value;
            var done = _ref.done;

            if (done) return success(value);
            next(value);
          } catch (e) {
            return error(e);
          }
        };
      };

      var next = function next(ret) {
        controls.some(function (control) {
          return control(ret, next, runtime, yieldValue(false), yieldValue(true));
        });
      };

      yieldValue(false)();
    };

    var iterator = _is2.default.iterator(input) ? input : regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return input;

            case 2:
              return _context.abrupt('return', _context.sent);

            case 3:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })();

    iterate(iterator, success, error);
  };

  return runtime;
};

exports.default = create;