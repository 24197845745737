interface Props {
	slug: string;
	data: any;
	onComplete: () => void;
}

const CustomerRender: React.FC< Props > = ( {} ) => {
	return <div>Not available</div>;
};

export default CustomerRender;
